.dash__card {
    background: white;
    border-radius: 16px;
    padding: 24px;
    min-width: 280px;
    display: inline-block;
    margin-right: 24px;
    margin-bottom: 24px;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
    transition: all 0.15s;
    position: relative;
}

.dash__card:hover {
    transform: scale(1.05);
    box-shadow: 15px 15px 35px rgba(67, 74, 93, 0.04);
    cursor: pointer;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: 1;
}

.dash__key {
    position: relative;
    font-size: 0.9em;
}

.dash__card span {
    display: block;
}

.dash__card {
    padding-top: 24px;
}

.dash__card .button__wrapper {
    position: absolute;
    bottom: 24px;
    width: 100%;
    transform: translateY(100%);
    z-index: 1;
    background: white;
    left: 0px;
    padding: 0px 24px;
    padding-bottom: 24px;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    opacity: 0;
    transition: all 0.15s;
    display: none;
}

.dash__card:not(.dash__card--disabled):not(.dash__card--expired):not(.dash__card--editDisabled):hover .button__wrapper {
    opacity: 1;
    display: block;
}

.dash__card .dash__edit {
    display: none;
    padding: 4px;
    background: rgb(70, 65, 65);
    color: white;
    font-size: 0.9em;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    top: -8px;
    width: 100%;
    text-align: center;
    border-radius: 4px;
}

.dash__card--editDisabled:hover .dash__edit {
    display: block;
}

.dash__value {
    font-size: 1.1em;
    margin: 8px 0px;
}

.dash__card--disabled, .dash__card--expired {
    opacity: 0.6;
}

.dash__card--disabled:hover {
    cursor: not-allowed;
    transform: scale(1);
}