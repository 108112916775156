.login-form__holder {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    padding: 42px;
    padding-top: 16px;
    background: white;
    border-radius: 12px;
    box-shadow: 5px 5px 20px rgba(108, 120, 172, 0.12);
    width: 100%;
    max-width: 440px;
}

.login__h1 {
    font-size: 2.5em;
    line-height: 0.5em;
}

.login__h2 {
    line-height: 0.5em;
    font-weight: 500;
    margin-bottom: 48px;
    display: block;
}

.button.login__form__button {
    width: 100%;
    border-radius: 6px;
    background: #0054FC;
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 12px;
    border: none;
    transition: all 0.15s;
    justify-content: center;
}

.login__form__input {
    width: 100%;
    font-size: 18px;
    padding: 12px;
}

.form__button:disabled, .form__button:disabled:hover {
    background: #A6AAB4;
    color: #434A5D;
    cursor: not-allowed;
}

.form__button:hover {
    background: #db540b;
    cursor: pointer;
}

.form__combo {
    margin-bottom: 24px;
}

.form__logo {
    position: absolute;
    bottom: 25px;
    left: 50%;
    margin-left: -90px;
    width: 180px;
    z-index: -1;
}