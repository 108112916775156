.frame__sidebar {
    width: 80px;
    height: 100%;
    background: white;
    position: fixed;
    top: 0px;
    border-right: 1px solid #e9eaee;
    display: flex;
    flex-direction: column;
}

.frame__logo img {
    max-width: 100%;
}

.frame__logo {
    height: 80px;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px;
}

.frame__header {
    height: 80px;
    width: calc(100% - 80px);
    position: absolute;
    top: 0px;
    left: 80px;
    background: white;
    border-bottom: 1px solid #e9eaee;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    align-items: center;
    padding: 12px;
}

.frame__header__username {
    font-weight: 500;
}

.frame__header__logout {
    margin-top: 4px;
    display: block;
}

.frame__header__logout:hover {
    color: #0054FC;
    cursor: pointer;
}

.frame__sidebar__icons {
    text-align: center;
    margin-top: 24px;
}

.frame__sidebar__icon {
    height: 25px;
    width: 25px;
    margin: 12px auto;
    color: #A6AAB4;
}

.frame__sidebar__icon--secondary {
    height: 20px;
    width: 20px;
}

.frame__sidebar__icon__tooltip {
    position: absolute;
    right: -25px;
    opacity: 0;
    max-height: 0px;
    top: 50%;
    text-align: left;
    transform: translateY(-50%) translateX(100%);
    white-space: nowrap;
    background: #434A5D;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 5px 5px 10px rgba(67, 74, 93, 0.12);
}

.frame__sidebar__icon__holder:hover > .frame__sidebar__icon__tooltip {
    opacity: 1;
    max-height: 30px;
    right: -5px;
    transition: opacity 0.35s, right 0.35s;
    padding: 6px 8px;
}

.frame__sidebar__icon__holder {
    position: relative;
    display: block;
    text-decoration: none;
}

.frame__sidebar__icon__holder:hover > .frame__sidebar__icon, .frame__sidebar__icon--active {
    color: #0054FC;
}

.frame__sidebar__icon__holder--active::before {
    position: absolute;
    left: 50%;
    height: 45px;
    top: 50%;
    width: 45px;
    margin-top: -22.5px;
    margin-left: -22.5px;
    background-color: #0054FC;
    opacity: 0.1;
    border-radius: 8px;
    content: '';
    display: block;
}

.frame__sidebar__icon__holder--secondary--active::before {
    position: absolute;
    left: 10px;
    height: 5px;
    top: 50%;
    width: 5px;
    border-radius: 10px;
    margin-top: -5px;
    background-color: #0054FC;
    content: '';
    display: block;
}

.frame {
    height: 100%;
}

.frame__children {
    display: block;
    padding: 120px 140px;
    padding-right: 40px;
}