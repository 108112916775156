.button {
    border: 0px;
    border-radius: 6px;
    padding: 12px;
    font-size: 15px;
    margin-right: 12px; 
    transition: all 0.2s;
    display: inline-flex;
    align-items: center;
}

.button:hover {
    cursor: pointer;
}

.button:disabled, .button:disabled:hover {
    background: #e9eaee;
    color: #d3d4da;
    cursor: not-allowed;
    box-shadow: none;
}

.button--primary {
    background: #0054FC;
    color: white;
    box-shadow: 0px 10px 15px -10px #0054fc6b;
}

.button--text {
    background: transparent;
    color: #0054FC;
    padding: 8px;
}

.button--text:hover {
    background: #0054FC;
    color: white;
}

.button--text:disabled, .button--text:disabled:hover {
    background: transparent;
    color: #8b8c91;
    cursor: not-allowed;
    box-shadow: none;
}

.button--primary:hover {
    background: #0148d4;
    box-shadow: 0px 10px 10px -10px #0054FC;
}

.button--secondary {
    background: #e9eaee;
    color: #434A5D;
}

.button--secondary:hover {
    background: #d3d4da;
}

.button--bold {
    font-weight: bold;
}