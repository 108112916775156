@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page__content {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
html, body, #root, .App {
  height: 100%;
  width: 100%;
  margin: 0;
  left: 0;
  top: 0;
}

h1, h2 {
  font-weight: 600;
}

a {
  text-decoration: none;
}

a:visited, a:link, a:active {
  color: inherit;
}

* {
  box-sizing: border-box;
}

body {
  background: #F4F5F8;
  color: #434A5D;
  font-family: 'Work Sans', sans-serif;
}

.color--secondary {
  color: #A6AAB4;
}

.pagination {
  margin: 24px 0px;
}

.input__label {
  display: block;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.input--style {
  border-radius: 6px;
  border: 2px solid #e9eaee;
  padding: 8px;
  font-size: 1em;
  width: 100%;
  transition: all 0.15s;
}

.input--style:focus {
  border: 2px solid #0054FC;
  outline: none;
}
.login-form__holder {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.login-form {
    padding: 42px;
    padding-top: 16px;
    background: white;
    border-radius: 12px;
    box-shadow: 5px 5px 20px rgba(108, 120, 172, 0.12);
    width: 100%;
    max-width: 440px;
}

.login__h1 {
    font-size: 2.5em;
    line-height: 0.5em;
}

.login__h2 {
    line-height: 0.5em;
    font-weight: 500;
    margin-bottom: 48px;
    display: block;
}

.button.login__form__button {
    width: 100%;
    border-radius: 6px;
    background: #0054FC;
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 12px;
    border: none;
    transition: all 0.15s;
    -webkit-justify-content: center;
            justify-content: center;
}

.login__form__input {
    width: 100%;
    font-size: 18px;
    padding: 12px;
}

.form__button:disabled, .form__button:disabled:hover {
    background: #A6AAB4;
    color: #434A5D;
    cursor: not-allowed;
}

.form__button:hover {
    background: #db540b;
    cursor: pointer;
}

.form__combo {
    margin-bottom: 24px;
}

.form__logo {
    position: absolute;
    bottom: 25px;
    left: 50%;
    margin-left: -90px;
    width: 180px;
    z-index: -1;
}
.frame__sidebar {
    width: 80px;
    height: 100%;
    background: white;
    position: fixed;
    top: 0px;
    border-right: 1px solid #e9eaee;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.frame__logo img {
    max-width: 100%;
}

.frame__logo {
    height: 80px;
    border-bottom: 1px solid #e9e9e9;
    padding: 16px;
}

.frame__header {
    height: 80px;
    width: calc(100% - 80px);
    position: absolute;
    top: 0px;
    left: 80px;
    background: white;
    border-bottom: 1px solid #e9eaee;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: right;
    -webkit-align-items: center;
            align-items: center;
    padding: 12px;
}

.frame__header__username {
    font-weight: 500;
}

.frame__header__logout {
    margin-top: 4px;
    display: block;
}

.frame__header__logout:hover {
    color: #0054FC;
    cursor: pointer;
}

.frame__sidebar__icons {
    text-align: center;
    margin-top: 24px;
}

.frame__sidebar__icon {
    height: 25px;
    width: 25px;
    margin: 12px auto;
    color: #A6AAB4;
}

.frame__sidebar__icon--secondary {
    height: 20px;
    width: 20px;
}

.frame__sidebar__icon__tooltip {
    position: absolute;
    right: -25px;
    opacity: 0;
    max-height: 0px;
    top: 50%;
    text-align: left;
    -webkit-transform: translateY(-50%) translateX(100%);
            transform: translateY(-50%) translateX(100%);
    white-space: nowrap;
    background: #434A5D;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 5px 5px 10px rgba(67, 74, 93, 0.12);
}

.frame__sidebar__icon__holder:hover > .frame__sidebar__icon__tooltip {
    opacity: 1;
    max-height: 30px;
    right: -5px;
    transition: opacity 0.35s, right 0.35s;
    padding: 6px 8px;
}

.frame__sidebar__icon__holder {
    position: relative;
    display: block;
    text-decoration: none;
}

.frame__sidebar__icon__holder:hover > .frame__sidebar__icon, .frame__sidebar__icon--active {
    color: #0054FC;
}

.frame__sidebar__icon__holder--active::before {
    position: absolute;
    left: 50%;
    height: 45px;
    top: 50%;
    width: 45px;
    margin-top: -22.5px;
    margin-left: -22.5px;
    background-color: #0054FC;
    opacity: 0.1;
    border-radius: 8px;
    content: '';
    display: block;
}

.frame__sidebar__icon__holder--secondary--active::before {
    position: absolute;
    left: 10px;
    height: 5px;
    top: 50%;
    width: 5px;
    border-radius: 10px;
    margin-top: -5px;
    background-color: #0054FC;
    content: '';
    display: block;
}

.frame {
    height: 100%;
}

.frame__children {
    display: block;
    padding: 120px 140px;
    padding-right: 40px;
}
.button {
    border: 0px;
    border-radius: 6px;
    padding: 12px;
    font-size: 15px;
    margin-right: 12px; 
    transition: all 0.2s;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
}

.button:hover {
    cursor: pointer;
}

.button:disabled, .button:disabled:hover {
    background: #e9eaee;
    color: #d3d4da;
    cursor: not-allowed;
    box-shadow: none;
}

.button--primary {
    background: #0054FC;
    color: white;
    box-shadow: 0px 10px 15px -10px #0054fc6b;
}

.button--text {
    background: transparent;
    color: #0054FC;
    padding: 8px;
}

.button--text:hover {
    background: #0054FC;
    color: white;
}

.button--text:disabled, .button--text:disabled:hover {
    background: transparent;
    color: #8b8c91;
    cursor: not-allowed;
    box-shadow: none;
}

.button--primary:hover {
    background: #0148d4;
    box-shadow: 0px 10px 10px -10px #0054FC;
}

.button--secondary {
    background: #e9eaee;
    color: #434A5D;
}

.button--secondary:hover {
    background: #d3d4da;
}

.button--bold {
    font-weight: bold;
}
.page__header {
    margin-bottom: 48px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 1080px;
}

.page__header--inverse {
    margin-top: 48px;
    margin-bottom: 24px;
}

.page__header--inverse:first-child {
    margin-top: 0px;
}

.page__header__actions {
    margin-left: auto;
}

.page__haeder__loader {
    margin-left: 12px;
    line-height: 1em;
}
.modal {
    position: fixed;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    left: 50%;
    border-radius: 8px;
    background: white;
    width: 100%;
    max-width: 640px;
    overflow: hidden;
}

.modal__overlay {
    position: fixed;
    background: #434A5D;
    opacity: 0.3;
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
}

.modal__header {
    display: -webkit-flex;
    display: flex;
    background: #434A5D;
    padding: 20px 24px;
}

.modal__header h2 {
    color: white;
    margin: 0px;
    line-height: 1em;
    font-weight: 600;
}

.modal__close {
    margin-left: auto;
    color: white;
    height: 20px;
    width: 20px;
}

.modal__content {
    padding: 24px;
}

.modal__buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding: 24px;
    padding-top: 0px;
}

.modal__notice {
    display: block;
    margin-bottom: 16px;
    font-weight: bold;
}
.license__status {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    display: block;
    margin: auto;
}

.license__status--active {
    background: rgb(3, 209, 147);
}

.license__status--hold {
    background: rgb(241, 167, 8);
}

.license__search {
    margin-bottom: 24px;
    display: -webkit-flex;
    display: flex;
}

.license__search__targets .form__group {
    margin: 0px;
    margin-right: 12px;
}

.license__search__targets {
    margin-left: 24px;
}

.license__search__filter {
    display: block;
    margin-top: 8px;
}

.edit--disabled {
    color: rgb(226, 36, 36) !important;
}

.edit--disabled__gear {
    opacity: 0.3 !important;
}

.edit--disabled__gear__link {
    position: relative;
}

.edit--disabled__gear__link:hover {
    cursor: not-allowed !important;
}

.edit--disabled__gear__link:hover::before {
    display: block;
}

.edit--disabled__gear__link::before {
    display: none;
    content: 'You can\'t edit this license right now.';
    position: absolute;
    top: -40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: rgb(67, 74, 93);
    color: white;
    white-space: nowrap;
    font-size: 14px;
    padding: 8px;
    border-radius: 6px;
    box-shadow: 3px 3px 5px rgba(67, 74, 93, 0.2);
    pointer-events: none;
}

.edit--enabled {
    color: rgb(3, 209, 147);
}
.dash__card {
    background: white;
    border-radius: 16px;
    padding: 24px;
    min-width: 280px;
    display: inline-block;
    margin-right: 24px;
    margin-bottom: 24px;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
    transition: all 0.15s;
    position: relative;
}

.dash__card:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 15px 15px 35px rgba(67, 74, 93, 0.04);
    cursor: pointer;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: 1;
}

.dash__key {
    position: relative;
    font-size: 0.9em;
}

.dash__card span {
    display: block;
}

.dash__card {
    padding-top: 24px;
}

.dash__card .button__wrapper {
    position: absolute;
    bottom: 24px;
    width: 100%;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    z-index: 1;
    background: white;
    left: 0px;
    padding: 0px 24px;
    padding-bottom: 24px;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    opacity: 0;
    transition: all 0.15s;
    display: none;
}

.dash__card:not(.dash__card--disabled):not(.dash__card--expired):not(.dash__card--editDisabled):hover .button__wrapper {
    opacity: 1;
    display: block;
}

.dash__card .dash__edit {
    display: none;
    padding: 4px;
    background: rgb(70, 65, 65);
    color: white;
    font-size: 0.9em;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    top: -8px;
    width: 100%;
    text-align: center;
    border-radius: 4px;
}

.dash__card--editDisabled:hover .dash__edit {
    display: block;
}

.dash__value {
    font-size: 1.1em;
    margin: 8px 0px;
}

.dash__card--disabled, .dash__card--expired {
    opacity: 0.6;
}

.dash__card--disabled:hover {
    cursor: not-allowed;
    -webkit-transform: scale(1);
            transform: scale(1);
}
.table {
    border-radius: 8px;
    background: white;
    border-collapse: collapse;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
}

.table tr {
    transition: background-color 0.15s;
}

.table tr:first-child:hover {
    background-color: transparent;
}

.table tr:last-child td {
    border-bottom: none;
}

.table td, .table th {
    padding: 16px 24px;
    border-bottom: 2px solid #e9eaee;
    text-align: center;
}

.table td:first-child {
    text-align: left;
}

.table tr:hover {
    background-color: #0055ff09;
}

.table tr .util {
    opacity: 0;
    transition: opacity 0.2s;
}

.table tr:hover .util {
    opacity: 1;
}

.table--disabled {
    opacity: 0.8;
    cursor: not-allowed;
}

.table--disabled tr:hover {
    background-color: transparent;
}

.table--disabled * {
    pointer-events: none;
    cursor: not-allowed;
}
.breadcrumbs__back {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.breadcrumbs__back__icon {
    margin-right: 2px;
    margin-left: 4px;
    transition: margin 0.15s;
}

.breadcrumbs__back:hover .breadcrumbs__back__icon {
    margin-left: 0px;
    margin-right: 6px;
}
.form {
    padding: 32px;
    background: white;
    border-radius: 8px;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
    display: inline-block;
}

.form--unset {
    display: block;
}

.form__group {
    margin-bottom: 20px;
}

.form__error {
    padding: 12px;
    margin-bottom: 32px;
    background: rgba(226, 36, 36, 0.05);
    border: 2px solid rgb(226, 36, 36);
    color: rgb(226, 36, 36);
    border-radius: 6px;
}

.form__label {
    display: block;
    margin-bottom: 8px;
    text-transform: capitalize;
}

.form__input {
    border-radius: 6px;
    border: 2px solid #e9eaee;
    padding: 8px;
    font-size: 1em;
    transition: all 0.15s;
}

.form__input:focus {
    border: 2px solid #0054FC;
    outline: none;
}
