.license__status {
    height: 8px;
    width: 8px;
    border-radius: 10px;
    display: block;
    margin: auto;
}

.license__status--active {
    background: rgb(3, 209, 147);
}

.license__status--hold {
    background: rgb(241, 167, 8);
}

.license__search {
    margin-bottom: 24px;
    display: flex;
}

.license__search__targets .form__group {
    margin: 0px;
    margin-right: 12px;
}

.license__search__targets {
    margin-left: 24px;
}

.license__search__filter {
    display: block;
    margin-top: 8px;
}

.edit--disabled {
    color: rgb(226, 36, 36) !important;
}

.edit--disabled__gear {
    opacity: 0.3 !important;
}

.edit--disabled__gear__link {
    position: relative;
}

.edit--disabled__gear__link:hover {
    cursor: not-allowed !important;
}

.edit--disabled__gear__link:hover::before {
    display: block;
}

.edit--disabled__gear__link::before {
    display: none;
    content: 'You can\'t edit this license right now.';
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: rgb(67, 74, 93);
    color: white;
    white-space: nowrap;
    font-size: 14px;
    padding: 8px;
    border-radius: 6px;
    box-shadow: 3px 3px 5px rgba(67, 74, 93, 0.2);
    pointer-events: none;
}

.edit--enabled {
    color: rgb(3, 209, 147);
}