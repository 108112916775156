.page__header {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    max-width: 1080px;
}

.page__header--inverse {
    margin-top: 48px;
    margin-bottom: 24px;
}

.page__header--inverse:first-child {
    margin-top: 0px;
}

.page__header__actions {
    margin-left: auto;
}

.page__haeder__loader {
    margin-left: 12px;
    line-height: 1em;
}