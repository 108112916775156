@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');

html, body, #root, .App {
  height: 100%;
  width: 100%;
  margin: 0;
  left: 0;
  top: 0;
}

h1, h2 {
  font-weight: 600;
}

a {
  text-decoration: none;
}

a:visited, a:link, a:active {
  color: inherit;
}

* {
  box-sizing: border-box;
}

body {
  background: #F4F5F8;
  color: #434A5D;
  font-family: 'Work Sans', sans-serif;
}

.color--secondary {
  color: #A6AAB4;
}

.pagination {
  margin: 24px 0px;
}

.input__label {
  display: block;
  margin-bottom: 8px;
  text-transform: capitalize;
}

.input--style {
  border-radius: 6px;
  border: 2px solid #e9eaee;
  padding: 8px;
  font-size: 1em;
  width: 100%;
  transition: all 0.15s;
}

.input--style:focus {
  border: 2px solid #0054FC;
  outline: none;
}