.form {
    padding: 32px;
    background: white;
    border-radius: 8px;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
    display: inline-block;
}

.form--unset {
    display: block;
}

.form__group {
    margin-bottom: 20px;
}

.form__error {
    padding: 12px;
    margin-bottom: 32px;
    background: rgba(226, 36, 36, 0.05);
    border: 2px solid rgb(226, 36, 36);
    color: rgb(226, 36, 36);
    border-radius: 6px;
}

.form__label {
    display: block;
    margin-bottom: 8px;
    text-transform: capitalize;
}

.form__input {
    border-radius: 6px;
    border: 2px solid #e9eaee;
    padding: 8px;
    font-size: 1em;
    transition: all 0.15s;
}

.form__input:focus {
    border: 2px solid #0054FC;
    outline: none;
}