.table {
    border-radius: 8px;
    background: white;
    border-collapse: collapse;
    box-shadow: 10px 10px 25px rgba(67, 74, 93, 0.05);
}

.table tr {
    transition: background-color 0.15s;
}

.table tr:first-child:hover {
    background-color: transparent;
}

.table tr:last-child td {
    border-bottom: none;
}

.table td, .table th {
    padding: 16px 24px;
    border-bottom: 2px solid #e9eaee;
    text-align: center;
}

.table td:first-child {
    text-align: left;
}

.table tr:hover {
    background-color: #0055ff09;
}

.table tr .util {
    opacity: 0;
    transition: opacity 0.2s;
}

.table tr:hover .util {
    opacity: 1;
}

.table--disabled {
    opacity: 0.8;
    cursor: not-allowed;
}

.table--disabled tr:hover {
    background-color: transparent;
}

.table--disabled * {
    pointer-events: none;
    cursor: not-allowed;
}